<template>
	<v-dialog :value='value' :width='665' class-content='border-radius a-timeline-dialog-tourist' @close-dialog="$emit('close-dialog')">
		<div class='a-timeline-dialog-tourist-content'>
			<a-select
				:placeholder='$t("account.booking.content.choose")'
				:options='items'
				v-model='touristId'
				class='a-select-count'
			>
				<template v-slot:count>
					<p class='a-select-count-text'>{{items.findIndex(el => el.id === touristId) + 1}} / {{items.length}}</p>
				</template>
			</a-select>
			<div class="a-timeline-dialog-tourist-form">
				<div class="a-timeline-dialog-tourist-form-group">
					<div class="a-timeline-dialog-tourist-form-col">
						<v-text-field
							:label='$t("account.form.label.first_name")'
							:placeholder='$t("account.form.placeholder.first_name")'
							name='name'
							type='text'
							v-model='form.name'
							required
							hide-details
							:invalid='!form.name && errors.name'
							isRegex
							:regex='/^[A-Za-z]+$/'
							isUpperCase
						/>
					</div>
					<div class="a-timeline-dialog-tourist-form-col">
						<v-text-field
							:label='$t("account.form.label.last_name")'
							:placeholder='$t("account.form.placeholder.last_name")'
							name='surname'
							type='text'
							v-model='form.surname'
							required
							hide-details
							:invalid='!form.surname && errors.surname'
							isRegex
							:regex='/^[A-Za-z]+$/'
							isUpperCase
						/>
					</div>
					<div class="a-timeline-dialog-tourist-form-col">
						<div class="a-timeline-dialog-tourist-form-radio">
							<v-radio
								id="man"
								name='gender'
								:label='$t("account.form.label.man")'
								value='man'
								v-model='form.gender'
							/>
							<v-radio
								id="woman"
								name='gender'
								:label='$t("account.form.label.woman")'
								value='woman'
								v-model='form.gender'
							/>
						</div>
					</div>
				</div>

				<div class="a-timeline-dialog-tourist-form-group">
					<div class="a-timeline-dialog-tourist-form-col">
						<p class='a-timeline-dialog-tourist-form-title'>{{$t("account.form.label.date_of_birth")}}</p>
					</div>
					<div class="a-timeline-dialog-tourist-form-col"></div>
					<div class="a-timeline-dialog-tourist-form-col">
						<a-dropdown-date-picker
							v-model='form.birthday'
							@update-date='form.birthday = $event, updateBirthday($event)'
							:invalid='!form.birthday && errors.birthday'
							:errorMessage='errorAgeBirthday'
						/>
					</div>
					<div class="a-timeline-dialog-tourist-form-col">
						<div class='a-add-passport-form-select'>
							<span class="app-label">{{$t("account.form.label.citizenship")}}: <sup>*</sup></span>
							<a-autocomplete
								:placeholder='$t("account.form.placeholder.citizenship")'
								v-model='form.nationality_code'
								:requestName='getAutocompleteNationality'
								item-value='code'
								actionInitial
								small
								append-icon='arrow-down'
								:initialLoading='initialLoading'
								:errors='!form.nationality_code && errors.nationality_code'
							/>
						</div>
					</div>
				</div>

				<div class="a-timeline-dialog-tourist-form-group">
					<div class="a-timeline-dialog-tourist-form-col">
						<p class='a-timeline-dialog-tourist-form-title'>{{$t("account.form.label.passport_data")}}</p>
						<div class="a-timeline-dialog-tourist-form-password">
							<v-text-field
								:label='$t("account.form.label.series_and_number")'
								placeholder="AA0000AA"
								name='number'
								v-model='form.number'
								required
								hide-details
								:invalid='!form.number && errors.number'
								isRegex
								:regex='/^[A-Za-z0-9]+$/'
								isUpperCase
							/>
						</div>
					</div>
					<div class="a-timeline-dialog-tourist-form-col">
						<p class='a-timeline-dialog-tourist-form-title'>{{$t("account.form.label.suitable_for")}}</p>
						<a-dropdown-date-picker
							v-model='form.date_expiration'
							@update-date='form.date_expiration = $event'
							isFuture
							:invalid='!form.date_expiration && errors.date_expiration'
						/>
					</div>
				</div>
			</div>
			<div class='a-timeline-dialog-tourist-footer'>
				<div class='a-timeline-dialog-tourist-footer-col'>
					<template v-if='items.length === items.findIndex(el => el.id === touristId) + 1'>
						<v-button block xxsmall color='green white--text'
							@click='updateOrderTourists("close")'
							:loading='loading_btn'
							:disabled='errorAgeBirthday.length ? true : false'
						>{{$t("account.buttons.continue")}}</v-button>
					</template>
					<template v-else>
						<v-button block xxsmall color='green white--text'
							@click='updateOrderTourists("next")'
							:loading='loading_btn'
							:disabled='errorAgeBirthday.length ? true : false'
						>{{$t("account.buttons.the_next_tourist")}}</v-button>
					</template>
				</div>
			</div>
		</div>
	</v-dialog>
</template>

<script>
	import ADropdownDatePicker from '@/components/account/ADropdownDatePicker';
	import {
		updateOrderTourists,
		getAutocompleteNationality
	} from '@/services/request';

	export default {
		data: () => ({
			form: {
				name: '',
				surname: '',
				gender: '',
				number: '',
				birthday: null,
				nationality_code: "",
				date_expiration: null
			},
			touristId: null,
			loading_btn: false,
			initialLoading: false,
			errors: {},
			errorAgeBirthday: ""
		}),
		props: ['value', 'items'],
		components: {
			ADropdownDatePicker
		},
		methods: {
			updateBirthday($event) {
				const adult = 16;
				let adultOrChildren = this.$moment().diff(this.$moment($event ?? this.form.birthday), 'year');

				if(this.form.type === "adult") {
					this.errorAgeBirthday = adultOrChildren >= adult ? "" : `${this.$t('account.form.message.errors.date_of_birth_of_an_adult_tourist_must_be_before')} ${this.$moment().subtract(adult, 'year').format("DD/MM/YYYY")}`;
				} else {
					this.errorAgeBirthday = adultOrChildren < adult ? "" : `${this.$t('account.form.message.errors.child_date_of_birth_must_be_at_least')} ${this.$moment().subtract(adult, 'year').add(1, 'day').format("DD/MM/YYYY")}`;
				}
			},
			getAutocompleteNationality,
			updateOrderTourists(type) {
				this.loading_btn = true;

				updateOrderTourists({
					orderId: this.$route.params.orderId,
					touristId: this.touristId,
					data: this.form
				}).then(() => {
					this.$store.commit('notification/successMessage', {title: this.$t('account.messages.success.tourist_successfully_update')});
					this.$emit("update-tourists");
					type === "close" ? this.$emit('close-dialog') : this.nextTourist();
				}).catch(err => {
					this.errors = err.response.data?.errors ?? {};
				}).finally(() => this.loading_btn = false)
			},
			nextTourist() {
				const findIndex = this.items.findIndex(el => el.id === this.touristId) + 1;
				this.touristId = this.items[findIndex].id;
				this.form = Object.assign({}, this.items.find(el => el.id === this.touristId));
				this.errors = {};
			}
		},
		watch: {
			touristId() {
				this.form = Object.assign({}, this.items.find(el => el.id === this.touristId));
				this.initialLoading = true;
				this.errors = {};
			},
			value() {
				if(this.value) {
					this.touristId = this.items[0].id;
					this.form = Object.assign({}, this.items.find(el => el.id === this.touristId));
					this.initialLoading = true;
				} else {
					this.form = {
						name: '',
						surname: '',
						gender: '',
						number: '',
						birthday: null,
						nationality_code: "",
						date_expiration: null
					}

					this.loading_btn = false;
					this.initialLoading = false;
					this.errors = {};
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-timeline-dialog-tourist {
		padding: 0;
		padding-top: 30px;
		&-title {
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 30px;
		}
		&-form {
			padding: 0 15px 50px;
			@include minw( $grid-breakpoints-xs ) {
				padding: 0 30px 50px;
			}
			&-group {
				display: flex;
				flex-wrap: wrap;
				flex: 1 1 auto;
				margin: 0 -12px;
				margin-top: 35px;
			}
			&-col {
				flex: 0 0 100%;
				max-width: 100%;
				padding-right: 12px;
				padding-left: 12px;
				@include minw( $grid-breakpoints-xs ) {
					flex: 0 0 calc(100% / 2);
					max-width: calc(100% / 2);
				}
			}
			&-title {
				font-size: 16px;
				font-weight: 400;
				margin-bottom: 15px;
			}
			.app-input {
				margin-bottom: 12px;
				width: 100%;
			}
			&-radio {
				display: flex;
				flex-wrap: wrap;
				.app-radio {
					&:not(:last-child) {
						margin-right: 40px;
					}
				}
			}
			&-password {
				display: flex;
				&-series {
					width: 76px !important;
					margin-right: 15px;
					flex-shrink: 0;
				}
			}
		}
		&-footer {
			display: flex;
			justify-content: center;
			padding: 20px;
			box-shadow: 0px 0px 10px rgba(86, 86, 86, 0.1);
			.app-btn {
				min-width: 186px !important;
			}
		}
	}
</style>
