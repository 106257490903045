<template>
	<div class='a-itinerary-info'>
		<div class='a-itinerary-info-wrap'>
			<!-- <div class='a-itinerary-info-status'>
				<p class='a-text-with-icon error--text'>
					<v-icon icon='refund' size='13' />
					<span>CDG</span>
					<v-icon class='a-itinerary-info-status-icon' icon='arrow-right' size='9' />
					<span>KBP {{$t("account.itinerary.info.no_longer_available")}}</span>
				</p>
				<p class='a-text-with-icon orange--text'>
					<v-icon icon='share' size='13' />
					<span>CDG</span>
					<v-icon class='a-itinerary-info-status-icon' icon='arrow-right-to-left' size='11' />
					<span>KBP {{$t("account.itinerary.info.the_price_has_risen_by")}} 35,86 грн...</span>
				</p>
				<span class='a-text-with-icon orange--text' style='margin-left: 23px'><strong>{{$t("account.itinerary.info.now")}} 11 054 грн</strong></span>
			</div> -->
			<div class='a-itinerary-info-box'>
				<p class='a-itinerary-info-title'>{{$t("account.itinerary.info.the_total_cost")}}</p>
				<p class='a-itinerary-info-total'>{{$helpers.amount(price.total, price.currency)}}</p>
				<p class='a-itinerary-info-descr'>{{$t("account.itinerary.markup")}}: {{$helpers.amount(price.margin, price.currency)}}</p>
				<p class='a-itinerary-info-descr'>{{$t("account.itinerary.amount_paid")}}: {{$helpers.amount(price.payment, price.currency)}}</p>
				<div class='a-itinerary-info-group-buttons'>
					<div class='a-itinerary-info-button'>
						<v-button xxsmall color="green white--text" @click='toBook'>{{$t("account.buttons.continue")}}</v-button>
					</div>
					<div class='a-itinerary-info-button block' @click='addToSuitcase'>
						<v-button block outline xxsmall color="green">{{$t("account.itinerary.info.save_suitcase")}}</v-button>
					</div>
				</div>
			</div>
		</div>

		<div class='tablet-hide' v-if='isCharterFlight'>
			<a-option-details />
		</div>
	</div>
</template>

<script>
	import AOptionDetails from '@/views/account/itinerary/option-details';
	import { addToSuitcase, toBook } from '@/services/request';

	export default {
		props: ['price', 'isCharterFlight'],
		components: {
			AOptionDetails
		},
		methods: {
			addToSuitcase() {
				this.loading_suitcase = true;

				addToSuitcase(this.$route.params.orderId).then(() => {
					this.$router.push({ name: "trips", params: {lang: this.$route.params.lang} })
				})
			},
			toBook() {
				this.$emit("open-dialog", true);

				toBook(this.$route.params.orderId).then(() => {
					this.$router.push({ name: "my-orders" })
				}).catch((err => {
					let data = err.response.data;
					this.$store.commit('notification/errorMessage', {title: data.message})
				})).finally(() => this.$emit("open-dialog", false))
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-itinerary-info {
		flex-shrink: 0;
		width: 100%;
		@include minw( $grid-breakpoints-sm ) {
			position: sticky;
			position: -webkit-sticky;
			top: 100px;
			z-index: 5;
			width: 390px * .8;
		}
		@include minw( $grid-breakpoints-md ) {
			width: 390px;
		}
		&-wrap {
			@include minw( $grid-breakpoints-sm ) {
				border: 1px solid rgba($black, .1);
				border-radius: $border-radius;
			}
			@include maxw( $grid-breakpoints-sm - 1 ) {
				display: flex;
				flex-direction: column-reverse;
			}
		}
		&-box {
			padding: 15px;
			@include minw( $grid-breakpoints-sm ) {
				padding: 20px * .6 25px * .6;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 20px 25px;
			}
			@include maxw( $grid-breakpoints-sm - 1 ) {
				display: grid;
				justify-content: center;
				text-align: center;
				margin: 0 -20px;
				box-shadow: 0px 0px 10px rgba(86, 86, 86, 0.1);
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				margin: 0 -10px;
			}
		}
		&-status {
			padding: 15px;
			@include minw( $grid-breakpoints-sm ) {
				padding: 14px * .6 25px * .6;
				border-bottom: 1px solid rgba($black, .1);
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 14px 25px;
			}
			@include maxw( $grid-breakpoints-sm - 1 ) {
				display: grid;
				justify-content: center;
			}
			&-icon {
				margin-right: 6px !important;
				margin-left: 6px;
				&.app-icon svg {
					fill: none;
					stroke: currentColor;
				}
			}
		}
		&-title {
			font-size: 10px;
			font-weight: 400;
			color: rgba($black, .5);
			@include minw( $grid-breakpoints-xs ) {
				font-size: 13px;
				margin-bottom: 10px;
			}
		}
		&-total {
			font-size: 18px;
			font-weight: 700;
			line-height: 1;
			margin-bottom: 5px;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 22px;
				margin-bottom: 10px;
			}
		}
		&-descr {
			font-size: 12px;
			font-weight: 400;
			color: rgba($black, .7);
			@include minw( $grid-breakpoints-xs ) {
				margin-bottom: 2px;
			}
		}
		&-group-buttons {
			display: flex;
			align-items: center;
			margin-top: 15px;
			margin-left: -2px;
			margin-right: -2px;
			@include minw( $grid-breakpoints-xs ) {
				margin-top: 20px;
				margin-left: -8px * .8;
				margin-right: -8px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				margin-left: -8px;
				margin-right: -8px;
			}
		}
		&-button {
			padding-left: 2px;
			padding-right: 2px;
			@include minw( $grid-breakpoints-xs ) {
				padding-left: 8px * .8;
				padding-right: 8px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				padding-left: 8px;
				padding-right: 8px;
			}
			&.block {
				width: 100%;
			}
			.app-btn--xxsmall {
				padding: 0 8px;
				font-size: 15px;
				@include maxw( $grid-breakpoints-xs - 1) {
					height: 34px;
					font-size: 14px;
				}
				@include minw( $grid-breakpoints-md ) {
					padding: 0 16px;
				}
			}
		}
	}
</style>
