<template>
	<div class='a-itinerary-info-option-wrap'>
		<div class='a-itinerary-info-option-box'>
			<p class='a-itinerary-info-option-title'>{{$t("account.itinerary.info.additional_information")}}</p>
			<p class='a-itinerary-info-option-text' v-if='optionDetails'>{{$t("account.itinerary.info.additional_information_descr")}}</p>
			<p class='a-itinerary-info-option-text green--text' style='cursor: pointer' v-else @click='optionDetails = true'>{{$t("account.itinerary.info.receipt_documents_and_tariff_rules")}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			optionDetails: false
		})
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-itinerary-info-option {
		&-wrap {
			border: 1px solid rgba($black, .1);
			border-radius: $border-radius;
			@include minw( $grid-breakpoints-sm ) {
				margin-top: 15px;
			}
			@include maxw( $grid-breakpoints-sm - 1 ) {
				margin-bottom: 80px;
			}
		}
		&-box {
			padding: 15px;
			@include minw( $grid-breakpoints-sm ) {
				padding: 20px * .6 25px * .6;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 20px 25px;
			}
		}
		&-title {
			font-size: 16px;
			font-weight: 400;
			margin-bottom: 15px;
			color: rgba($black, .5);
		}
		&-text {
			font-size: 13px;
			font-weight: 400;
			line-height: 1.5;
		}
	}
</style>
