<template>
	<v-dialog :value='value' :width='423' :showCloseCross='false' class-content='border-radius a-timeline-dialog-next' @close-dialog="$emit('close-dialog')">
		<div class='a-timeline-dialog-next-content'>
			<p class='a-timeline-dialog-next-title'>{{$t("account.itinerary.next.title")}}</p>
			<v-icon icon='loading' size='18' class='a-timeline-dialog-next-icon' />
			<p class='a-timeline-dialog-next-text'>{{$t("account.itinerary.next.description")}}</p>
		</div>
	</v-dialog>
</template>

<script>
	export default {
		props: ['value']
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-timeline-dialog-next {
		padding: 30px 35px 35px;
		@include maxw( $grid-breakpoints-xs - 1 ) {
			max-width: 100% !important;
			height: auto;
			margin: 10px;
			width: calc(100% - 20px);
		}
		&-title {
			font-size: 20px;
			font-weight: 700;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				font-size: 18px;
				font-weight: 400;
			}
		}
		&-icon {
			margin: 25px auto;
			animation: rotating .6s linear infinite;
			color: $green;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				margin: 15px auto;
			}
			svg {
				fill: none;
				path {
					stroke: currentColor;
					stroke-width: 2px;
				}
				circle {
					stroke: currentColor;
					stroke-width: 2px;
					stroke-opacity: .25;
				}
			}
		}
		&-text {
			font-size: 13px;
			font-weight: 400;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				font-size: 12px;
			}
		}
	}

	@keyframes rotating {
		from { transform: rotate(0deg); }
		to { transform: rotate(360deg); }
	}
</style>
