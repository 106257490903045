<template>
	<edit-insurance-visa
		:value='value'
		@input='$emit("input", $event)'
		:title='`${$t("account.itinerary.insurance.insurance_for")} ${title}`'
	>
		<template v-slot:table>
			<template v-if='loading'>
				<table class='a-edit-insurance-table'>
					<tbody>
						<tr>
							<td class='a-edit-insurance-table-header-cell' width='100%'>
								<div>
									<p class='a-edit-insurance-table-header-title'><span class='skeleton' :style='`width: 130px; height: 19px`' /></p>
									<p class='a-edit-insurance-table-header-date'><span class='skeleton' :style='`width: 200px; height: 15px`' /></p>
								</div>
							</td>
							<td class='a-edit-insurance-table-header-cell'>
								<div>
									<span class='skeleton' :style='`width: 24px; height: 24px; margin: auto; margin-bottom: 5px;`' />
									<span class='skeleton' :style='`width: 60px; height: 32px`' />
								</div>
							</td>
							<td class='a-edit-insurance-table-header-cell'>
								<div>
									<span class='skeleton' :style='`width: 24px; height: 24px; margin: auto; margin-bottom: 5px;`' />
									<span class='skeleton' :style='`width: 60px; height: 32px`' />
								</div>
							</td>
							<td class='a-edit-insurance-table-header-cell'>
								<div>
									<span class='skeleton' :style='`width: 24px; height: 24px; margin: auto; margin-bottom: 5px;`' />
									<span class='skeleton' :style='`width: 60px; height: 32px`' />
								</div>
							</td>
							<td class='a-edit-insurance-table-header-cell'>
								<div>
									<span class='skeleton' :style='`width: 24px; height: 24px; margin: auto; margin-bottom: 5px;`' />
									<span class='skeleton' :style='`width: 60px; height: 32px`' />
								</div>
							</td>
						</tr>
						<tr v-for='(item, i) in 3' :key='i'>
							<td class='a-edit-insurance-table-content-cell'>
								<span class='skeleton' :style='`width: 100%; height: 15px`' />
							</td>
							<td class='a-edit-insurance-table-content-cell'>
								<span class='skeleton' :style='`width: 60px; height: 15px`' />
							</td>
							<td class='a-edit-insurance-table-content-cell'>
								<span class='skeleton' :style='`width: 60px; height: 15px`' />
							</td>
							<td class='a-edit-insurance-table-content-cell'>
								<span class='skeleton' :style='`width: 60px; height: 15px`' />
							</td>
							<td class='a-edit-insurance-table-content-cell'>
								<span class='skeleton' :style='`width: 60px; height: 15px`' />
							</td>
						</tr>
					</tbody>
				</table>
			</template>
			<template v-else>
				<table class='a-edit-insurance-table' v-if='items.length'>
					<tbody>
						<tr>
							<td class='a-edit-insurance-table-header-cell' width='100%'>
								<div>
									<p class='a-edit-insurance-table-header-title'>{{$t("account.itinerary.insurance.insurance_period")}}:</p>
									<p class='a-edit-insurance-table-header-date'>{{$t("account.with")}} {{$moment(order.dateFrom).format("DD/MM/YYYY")}} {{$t("account.by")}} <template v-if='order.dateTo'> - {{$moment(order.dateTo).format("DD/MM/YYYY")}}</template></p>
								</div>
							</td>
							<td class='a-edit-insurance-table-header-cell'>
								<div>
									<v-icon icon='insurance/cancel' size='24' />
									<span>{{$t("account.itinerary.insurance.cancellation_trip")}}</span>
								</div>
							</td>
							<td class='a-edit-insurance-table-header-cell'>
								<div>
									<v-icon icon='insurance/tarrif' size='24' />
									<span>{{$t("account.itinerary.insurance.tariff_coverage")}}</span>
								</div>
							</td>
							<td class='a-edit-insurance-table-header-cell'>
								<div>
									<v-icon icon='insurance/medical' size='24' />
									<span>{{$t("account.itinerary.insurance.medical_expenses")}}</span>
								</div>
							</td>
							<td class='a-edit-insurance-table-header-cell'>
								<div>
									<v-icon icon='insurance/accident' size='24' />
									<span>{{$t("account.itinerary.insurance.accident")}}</span>
								</div>
							</td>
						</tr>
						<tr v-for='(item, i) in items' :key='i'>
							<td class='a-edit-insurance-table-content-cell'>
								<v-radio
									:value='item.id'
									:label='item.name'
									v-model='offerId'
									styled='checkbox'
									@input="clearTrip"
								/>
							</td>
							<td class='a-edit-insurance-table-content-cell' v-if='item.code'>
								<v-checkbox
									:name='`cancel-${item.id}`'
									styled='checkbox'
									v-model='item.cancelTrip'
									:disabled='!(item.tripCancellationAvailable && item.id === offerId)'
									@change="changeTrip"
								/>
							</td>
							<td class='a-edit-insurance-table-content-cell' v-if='item.code'>
								<span>{{$helpers.numberWithSpaces(item.covering)}} €</span>
							</td>
							<td class='a-edit-insurance-table-content-cell' v-if='item.code'>
								<div>
									<v-icon icon='check' size='12' />
								</div>
							</td>
							<td class='a-edit-insurance-table-content-cell' v-if='item.code'>
								<div>
									<v-icon icon='check' size='12' />
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<div v-else>
					<p class='a-edit-insurance-table-header-title'>{{$t("account.itinerary.insurance.there_is_no_insurance_for_this_tour")}}</p>
				</div>
			</template>
		</template>
		<template v-slot:details v-if='items.length'>
			<a href='https://api.bravo-travel.online/pdf/erv.pdf' target="_blank" class='app-link green-persian--text a-edit-insurance-dialog-link'>{{$t("account.itinerary.detailed_information")}}</a>
			<a href='https://api.bravo-travel.online/pdf/erv_general_conditions_ru.pdf' target="_blank" class='app-link green-persian--text a-edit-insurance-dialog-link'>{{$t("account.itinerary.general_insurance_conditions")}}</a>
		</template>
		<template v-slot:actionButton='{closeDialog}' v-if='items.length'>
			<v-button xxsmall color='green white--text' @click.stop='addOrder(closeDialog)' :loading='loading_btn' :disabled='!offerId'>{{$t("account.itinerary.insurance.add_insurance")}}</v-button>
		</template>
	</edit-insurance-visa>
</template>

<script>
	import EditInsuranceVisa from "@/views/account/itinerary/components/edit-insurance-visa";
	import { getOrderInsurances, addOrder } from '@/services/request';

	export default {
		data: () => ({
			items: [],
			offerId: null,
			cancelTrip: false,
			loading: false,
			loading_btn: false
		}),
		props: ['value', 'user', 'order', 'initialItems'],
		computed: {
			title() {
				let name = this.user.firstName ?? this.user.name;
				let surname = this.user.lastName ?? this.user.surname;

				return name
					? (surname
						? `${name} ${surname}`
						: name)
					: `${this.$t('account.tourist')} ${this.index + 1}`;
			}
		},
		components: {
			EditInsuranceVisa
		},
		methods: {
			changeTrip() {
				this.cancelTrip = this.items.some(el => el.tripCancellationAvailable);
			},
			clearTrip() {
				this.cancelTrip = false;
				this.items = this.items.map(el => {
					return {
						...el,
						cancelTrip: false
					}
				})
			},
			addOrder(closeDialog) {
				this.loading_btn = true;

				addOrder({
					details: {
						cancelTrip: this.cancelTrip,
						touristId: this.user.id
					},
					type: "insurance",
					orderId: this.order.id,
					offerId: String(this.offerId)
				}).then(res => {
					this.$emit("update-order", res.data);
					closeDialog();
				}).catch(err => {
					let data = err.response.data;
					this.$store.commit('notification/errorMessage', {title: data.message})
				}).finally(() => this.loading_btn = false)
			}
		},
		watch: {
			value() {
				if(this.value) {
					this.loading = true;

					getOrderInsurances(this.order.id)
						.then(res => {
							let findInsurance = this.initialItems.find(el => el.tourist.id === this.user.id);
							this.offerId = findInsurance?.id ?? null;
							this.items = res.data.map(el => {
								if(this.offerId === el.id) {
									return {
										...el,
										cancelTrip: findInsurance?.cancelTrip ?? false
									}
								} else {
									return el
								}
							})
						})
						.finally(() => this.loading = false)
				} else {
					this.items = [];
					this.offerId = null;
				}
			}
		}
	}
</script>
