<template>
	<div ref='a-card-details' @keydown.esc="closeDialog" tabindex='0'>
		<transition name="translate-right">
			<div class='a-card-details a-edit-insurance-dialog' v-if='value'>
				<div class='a-card-details-wrap' v-click-outside="closeDialog">
					<div class='a-card-details-header a-edit-insurance-dialog-header'>
						<div class="row align-items-center">
							<div class="col">
								<p class='a-card-details-header-title'>
									<span class='a-card-details-header-title-arrow-back' @click.stop='closeDialog'>
										<v-icon icon='arrow-prev' size='14' />
									</span>
									{{title}}
								</p>
							</div>
							<div class="col-auto">
								<div class='a-card-details-header-button' @click.stop='closeDialog'>
									<v-icon icon='cross' size='17' />
								</div>
							</div>
						</div>
					</div>
					<div class='a-card-details-content a-edit-insurance-dialog-content'>
						<div class='a-edit-insurance-table-container'>
							<slot name='table' />
						</div>

						<div class='a-edit-insurance-details'>
							<slot name='details' />
						</div>

						<!-- <div class='a-edit-insurance-dialog-descr' v-if='more_text'>
							<p class='a-edit-insurance-dialog-descr-title'>Пакет “Активний туризм - Європа”</p>
							<p class='a-edit-insurance-dialog-descr-text'>Комплексне страхове покриття. У кожному пакеті - страхування медичних витрат і страхування від нещасного випадку. А також страхування багажу, страхування цивільної відповідальності, страхування для багаторазових поїздок і активного відпочинку.</p>
							<p class='a-edit-insurance-dialog-descr-text'>Чи можете ви з точністю передбачити, що відбудеться в наступні десять хвилин? Ви можете планувати, припускати, прогнозувати, але відбутися може що завгодно. Що вже говорити про подорож в інше місто або країну, яка триватиме кілька днів, тижнів або й місяців. Може загостритися хронічна хвороба, трапитися нещасний випадок на дорозі або в морі, вкусити бджола або змія, активізуватися вулкан чи накрити шторм. Кращий спосіб не думати про погане – відповідально до всього підготуватися.</p>
							<div class='a-edit-insurance-dialog-descr-table-container'>
								<table class='a-edit-insurance-dialog-descr-table'>
									<tbody>
										<tr>
											<th>{{$t("account.itinerary.visa.table.type_of_insurance")}}</th>
											<th>{{$t("account.itinerary.visa.table.insurance_program")}}</th>
											<th>{{$t("account.itinerary.visa.table.sum_insured")}}</th>
										</tr>
										<tr>
											<td>
												<div>
													<span>Медичні витрати</span>
													<span>Нещасний випадок</span>
													<span>Громадянська відповідальність</span>
												</div>
											</td>
											<td>
												<div>
													<span>А + активний відпочинок</span>
												</div>
											</td>
											<td>
												<div>
													<span>30 000 €</span>
													<span>3 000 €</span>
													<span>10 000 €</span>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<p class='a-edit-insurance-dialog-descr-text'>Комплексне страхове покриття. У кожному пакеті - страхування медичних витрат і страхування від нещасного випадку. А також страхування багажу, страхування цивільної відповідальності, страхування для багаторазових поїздок і активного відпочинку.</p>
							<p class='a-edit-insurance-dialog-descr-text'>Чи можете ви з точністю передбачити, що відбудеться в наступні десять хвилин? Ви можете планувати, припускати, прогнозувати, але відбутися може що завгодно. Що вже говорити про подорож в інше місто</p>
						</div>
						<span class='app-link green-persian--text a-edit-insurance-dialog-descr-more' @click='more_text = !more_text'>{{more_text ? $t("account.itinerary.collapse_detailed_information") : $t("account.itinerary.detailed_information")}}</span> -->
					</div>

					<div class='a-card-details-footer'>
						<div class='a-card-details-footer__divider'>
							<div class='divider' />
						</div>

						<div class="row align-items-center">
							<div class="col-auto ml-auto">
								<slot name='actionButton' :closeDialog='closeDialog' />
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<div class="backdrop" v-if='value'></div>
	</div>
</template>

<script>
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';

	export default {
		data: () => ({
			loading: false,
			more_text: false
		}),
		props: ['value', 'title', 'notCloseDialog'],
		methods: {
			closeDialog() {
				if(this.notCloseDialog) return;
				this.$emit('input', false);
			}
		},
		watch: {
			value() {
				setTimeout(() => {
					const hideEl = document.querySelector('.a-card-details-content')
					this.value ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150);
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-edit-insurance {
		&-dialog {
			&-header {
				.a-card-details-header-title {
					font-size: 18px;
					@include maxw( $grid-breakpoints-xs - 1 ) {
						font-size: 20px;
						font-weight: 400;
					}
				}
			}
			&-content {
				@include minw( $grid-breakpoints-xs ) {
					display: grid;
					grid-template-rows: auto 1fr auto;
					overflow: hidden;
				}
			}
			&-descr {
				@include minw( $grid-breakpoints-xs ) {
					overflow-x: hidden;
					overflow-y: auto;
				}
				&-title {
					font-size: 16px;
					font-weight: 400;
					line-height: 1.5;
					padding-top: 25px;
					margin-bottom: 15px;
				}
				&-text {
					font-size: 13px;
					font-weight: 400;
					line-height: 1.5;
					margin-bottom: 10px;
				}
				&-table {
					width: 100%;
					@include minw( $grid-breakpoints-xs ) {
						table-layout: fixed;
					}
					&-container {
						margin-top: 20px;
						margin-bottom: 25px;
						overflow-y: hidden;
						overflow-x: auto;
						width: 100%;
					}
					th {
						font-size: 13px;
						text-align: left;
						padding: 10px 15px;
						border: 1px solid #e5e5e5;
					}
					td {
						font-size: 12px;
						padding: 10px 15px;
						border: 1px solid #e5e5e5;
						@include maxw( $grid-breakpoints-xs - 1 ) {
							& > div {
								width: 230px;
							}
						}
						span {
							display: block;
							&:not(:last-child) {
								margin-bottom: 10px;
							}
						}
					}
				}
				&-more {
					font-size: 13px;
					display: inline-block;
					margin-top: 10px;
				}
			}
			&-link {
				font-size: 13px;
				font-weight: 400 !important;
				display: block;
				margin-top: 10px;
			}
		}
		&-table {
			width: 100%;
			td {
				vertical-align: middle;
			}
			&-container {
				overflow-y: hidden;
				overflow-x: auto;
				width: 100%;
			}
			&-header {
				&-title {
					font-size: 16px;
					font-weight: 700;
					margin-bottom: 5px;
				}
				&-date {
					font-size: 13px;
					font-weight: 400;
				}
				&-cell {
					padding-bottom: 15px;
					border-bottom: 1px solid rgba($black, .1);
					&:not(:first-child) {
						text-align: center;
						.app-icon {
							margin: auto;
							margin-bottom: 5px;
						}
						span {
							font-size: 12px;
							font-weight: 400;
							line-height: 1.4;
							display: block;
							color: rgba($black, .5);
						}
					}
					&:not(:last-child) {
						padding-right: 30px;
					}
				}
			}
			&-content {
				&-cell {
					padding: 15px 0;
					&:first-child {
						@include minw( $grid-breakpoints-xs ) {
							width: 50%;
						}
						@include maxw( $grid-breakpoints-xs - 1 ) {
							& > div {
								width: 350px;
							}
						}
					}
					&:not(:first-child) {
						text-align: center;
						white-space: nowrap;
						.app-checkbox {
							label {
								justify-content: center;
							}
							&-check {
								padding-right: 0;
							}
						}
						.app-icon {
							margin: auto;
							color: $green-persian;
						}
						span {
							font-size: 16px;
							font-weight: 700;
							display: block;
						}
					}
					&:not(:last-child) {
						padding-right: 30px;
					}
				}
			}
		}
	}
</style>
