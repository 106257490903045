<template>
	<div>
		<edit-insurance-visa
			:value='value'
			@input='$emit("input", $event)'
			:title='`${$t("account.itinerary.visa.visa_for")} ${title}`'
			:notCloseDialog='dialog'
		>
			<template v-slot:table>
				<template v-if='loading'>
					<table class='a-edit-insurance-table'>
						<tbody>
							<tr>
								<td class='a-edit-insurance-table-header-cell'>
									<div>
										<p class='a-edit-insurance-table-header-title'><span class='skeleton' :style='`width: 130px; height: 19px`' /></p>
									</div>
								</td>
								<td class='a-edit-insurance-table-header-cell'></td>
							</tr>
							<tr v-for='(el, i) in 3' :key='i'>
								<td class='a-edit-insurance-table-content-cell' style='width: 100%'>
									<span class='skeleton' :style='`width: 100%; height: 15px`' />
								</td>
								<td class='a-edit-insurance-table-content-cell'>
									<span class='skeleton' :style='`width: 60px; height: 15px`' />
								</td>
							</tr>
						</tbody>
					</table>
					<span class='a-edit-insurance-dialog-link'><span class='skeleton' :style='`width: 150px; height: 15px`' /></span>
				</template>
				<template v-else>
					<template v-if='Object.keys(items).length > 0'>
						<div class='a-edit-insurance-table-item' v-for='(item, i) in Object.keys(items)' :key='i'>
							<table class='a-edit-insurance-table'>
								<tbody>
									<tr>
										<td class='a-edit-insurance-table-header-cell'>
											<div>
												<p class='a-edit-insurance-table-header-title'>{{$t("account.itinerary.visa.choosing_visa")}} {{item}}:</p>
											</div>
										</td>
										<td class='a-edit-insurance-table-header-cell'></td>
									</tr>
									<tr v-for='(el, i) in items[item]' :key='i'>
										<td class='a-edit-insurance-table-content-cell' style='width: 100%'>
											<v-radio
												:value='`${item}-${el.id}`'
												:label='el.name'
												v-model='active_visa'
												styled='checkbox'
												style='width: auto'
												@input="changeVisa(el)"
											/>
										</td>
										<td class='a-edit-insurance-table-content-cell' v-if='el.price'>
											<span>{{$helpers.amount(el.price, el.currency)}}</span>
										</td>
									</tr>
								</tbody>
							</table>
							<template v-if='item === countryCode && description && description.length'>
								<div class='a-edit-insurance-dialog-descr' v-if='more_text' v-html='description'></div>
								<span class='app-link green-persian--text a-edit-insurance-dialog-descr-more' @click='more_text = !more_text'>{{more_text ? $t("account.itinerary.collapse_detailed_information") : $t("account.itinerary.detailed_information")}}</span>
							</template>
						</div>
					</template>
					<div v-else>
						<p class='a-edit-insurance-table-header-title'>{{$t("account.itinerary.visa.there_is_no_visa_for_this_tour")}}</p>
					</div>
				</template>
			</template>
			<template v-slot:actionButton='{closeDialog}' v-if='Object.keys(items).length'>
				<v-button xxsmall color='green white--text' @click.stop='addOrder(closeDialog)' :loading='loading_btn' :disabled='!offerId'>{{$t("account.itinerary.visa.add_visa")}}</v-button>
			</template>
		</edit-insurance-visa>

		<v-dialog :value='dialog' :width='497' class='a-edit-visa-dialog-date' class-content='border-radius a-edit-visa-dialog-date-content' @close-dialog="dialog = false, errorMessage = null">
			<div class='a-edit-visa-dialog-date-wrap'>
				<p class='a-edit-visa-dialog-date-title'>{{errorMessage}}</p>
				<p class='a-edit-visa-dialog-date-text'>{{$t("account.itinerary.visa.dialogs.description")}}</p>
				<v-button class='green white--text a-edit-visa-dialog-date-button' xxsmall @click.stop='dialog = false, errorMessage = null'>{{$t("account.itinerary.visa.dialogs.back")}}</v-button>
			</div>
		</v-dialog>
	</div>
</template>

<script>
	import EditInsuranceVisa from "@/views/account/itinerary/components/edit-insurance-visa";
	import { getOrderVisa, addOrder } from '@/services/request';

	export default {
		data: () => ({
			more_text: false,
			description: null,
			items: {},
			active_visa: null,
			offerId: null,
			countryCode: null,
			loading: false,
			loading_btn: false,
			dialog: false,
			errorMessage: null
		}),
		props: ['value', 'user', 'order', 'initialItems'],
		computed: {
			title() {
				let name = this.user.firstName ?? this.user.name;
				let surname = this.user.lastName ?? this.user.surname;

				return name
					? (surname
						? `${name} ${surname}`
						: name)
					: `${this.$t('account.tourist')} ${this.index + 1}`;
			}
		},
		components: {
			EditInsuranceVisa
		},
		methods: {
			changeVisa(item) {
				this.offerId = item.id;
				this.countryCode = item.countryCode;
				this.more_text = false;
				this.description = item.description;
			},
			addOrder(closeDialog) {
				this.loading_btn = true;

				addOrder({
					details: {
						countryCode: this.countryCode,
						touristId: this.user.id
					},
					type: "visa",
					orderId: this.order.id,
					offerId: String(this.offerId)
				}).then(res => {
					this.$emit("update-order", res.data);
					closeDialog();
				}).catch(err => {
					let data = err.response.data;
					this.errorMessage = data.message;
					this.dialog = true;
				}).finally(() => this.loading_btn = false)
			}
		},
		watch: {
			value() {
				if(this.value) {
					this.loading = true;

					getOrderVisa(this.order.id)
						.then(res => {
							this.items = res.data;
							// let findInsurance = Object.values(this.initialItems).find(el => el.tourist.id === this.user.id);
							// this.offerId = findInsurance?.id ?? null;
						})
						.finally(() => this.loading = false)
				} else {
					this.items = {};
					this.active_visa = null;
					this.offerId = null;
					this.countryCode = null;
					this.loading = false;
					this.loading_btn = false;
					this.dialog = false;
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-edit-visa-dialog-date {
		.app-popup {
			z-index: 20;
			overflow-y: auto;
		}
		.backdrop {
			z-index: 15;
		}
		&-content {
			@include maxw( $grid-breakpoints-xs - 1 ) {
				display: grid;
				align-items: center;
			}
			.app-popup__close {
				@include minw( $grid-breakpoints-xs ) {
					display: none;
				}
			}
		}
		&-wrap {
			text-align: center;
		}
		&-title {
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 10px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				font-weight: 400;
				margin-bottom: 15px;
			}
		}
		&-text {
			font-size: 16px;
			font-weight: 400;
			margin-bottom: 25px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				font-size: 13px;
				margin-bottom: 35px;
			}
		}
		&-button {
			min-width: 94px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				width: 100%;
			}
		}
	}
	.a-edit-insurance-table-item {
		&:not(:last-child) {
			margin-bottom: 50px;
		}
	}
</style>
