<template>
	<div ref='a-card-details' @keydown.esc="closeDialog" tabindex='0'>
		<transition name="translate-right">
			<div class='a-card-details a-list-insurance-dialog' v-if='value'>
				<div class='a-card-details-wrap' v-click-outside="closeDialog">
					<div class='a-card-details-header a-list-insurance-dialog-header'>
						<div class="row align-items-center">
							<div class="col">
								<p class='a-card-details-header-title'>{{title}}</p>
							</div>
							<div class="col-auto">
								<div class='a-card-details-header-button' @click.stop='closeDialog'>
									<v-icon icon='cross' size='17' />
								</div>
							</div>
						</div>
					</div>
					<div class='a-card-details-content'>
						<p class='a-list-insurance-dialog-descr'>{{descr}}</p>
						<div class='a-list-insurance-dialog-list'>
							<div class='a-list-insurance-dialog-list-item'
								v-for='(item, i) in order.paxes' :key='i'
							>
								<div @click='$emit("open-edit", item)'>
									<a-user
										:user="item"
										isBorder
										class='a-list-insurance-dialog-list-item-tourist'
										:class='{"active": findItem(item)}'
									/>
								</div>
								<template v-if='findItem(item)'>
									<v-icon icon='check' size='12' class='a-list-insurance-dialog-list-item-icon mobile-hide' />
									<div class='a-list-insurance-dialog-list-item-content'>
										<div class='a-list-insurance-dialog-list-item-content-header'>
											<p class='a-list-insurance-dialog-list-item-content-title'>{{findItem(item).name}}</p>
											<p class='app-link green--text a-list-insurance-dialog-list-item-content-link' @click='$emit("open-edit", item)'>{{$t("account.buttons.change")}}</p>
										</div>
										<p class='a-list-insurance-dialog-list-item-content-total'>{{$helpers.amount(findItem(item).price.total.amount, findItem(item).price.total.currency)}}</p>
									</div>
								</template>
							</div>
						</div>
					</div>

					<div class='a-card-details-footer'>
						<div class='a-card-details-footer__divider'>
							<div class='divider' />
						</div>

						<div class="row align-items-center">
							<div class="col-auto ml-auto">
								<v-button xxsmall color='green white--text' @click.stop='closeDialog'>{{$t("account.buttons.continue")}}</v-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<div class="backdrop" v-if='value'></div>
	</div>
</template>

<script>
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';

	export default {
		data: () => ({
			loading: false
		}),
		props: ['value', 'notCloseDialog', 'title', 'descr', 'order', 'items'],
		methods: {
			findItem({id}) {
				return this.items.find(el => el.tourist.id === id)
			},
			closeDialog() {
				if(this.notCloseDialog) return;
				this.$emit('input', false);
			}
		},
		watch: {
			value() {
				setTimeout(() => {
					const hideEl = document.querySelector('.a-card-details-content')
					this.value ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150);
			}
		}
	}
</script>
