<template>
	<form>
		<div class='a-booking-form-cars-checkboxs'>
			<v-checkbox
				name='return-place'
				:label='$t("account.home.cars.return_to_the_place_of_lease")'
				v-model='returnPlace'
			/>
		</div>
		<div class='a-booking-form-row'>
			<div class="a-booking-form-col" :class='{"a-booking-form-col-12": returnPlace}'>
				<select-defaults
					prepend-icon='navigation/cars'
					:placeholder='$t("account.home.cars.searchFrom")'
					v-model='form.airport'
					:errors='!form.country && errors.country'
				/>
			</div>
			<div class="a-booking-form-col" v-if='!returnPlace'>
				<select-defaults
					prepend-icon='navigation/cars'
					:placeholder='$t("account.home.cars.searchTo")'
					v-model='form.airport'
					:errors='!form.country && errors.country'
				/>
			</div>
			<div class="a-booking-form-col">
				<div class='a-booking-form-group-date'>
					<a-date-picker
						v-model='form.dateFrom'
						:placeholder='$t("account.home.cars.dateFrom")'
					/>
					<!-- <a-date-picker-mobile
						v-model='form.dateFrom'
						:placeholder='$t("account.home.cars.dateFrom")'
						class='mobile-show'
					/> -->
					<date-picker
						v-model='form.timeFrom'
						:time-picker-options="{
							start: '00:30',
							step: '00:30',
							end: '23:30',
						}"
						format="HH:mm"
						type="time"
						:placeholder='$t("account.home.cars.timeFrom")'
						:append-to-body='false'
						class='a-booking-form-timepicker'
						:editable='false'
					>
						<template v-slot:icon-calendar>
							<v-icon icon="arrow-down" size="14" />
						</template>
					</date-picker>
				</div>
			</div>
			<div class="a-booking-form-col">
				<div class='a-booking-form-group-date'>
					<a-date-picker
						v-model='form.dateTo'
						:placeholder='$t("account.home.cars.dateTo")'
					/>
					<!-- <a-date-picker-mobile
						v-model='form.dateTo'
						:placeholder='$t("account.home.cars.dateTo")'
						class='mobile-show'
					/> -->
					<date-picker
						v-model='form.timeTo'
						:time-picker-options="{
							start: '00:30',
							step: '00:30',
							end: '23:30',
						}"
						format="HH:mm"
						type="time"
						:placeholder='$t("account.home.cars.timeTo")'
						:append-to-body='false'
						class='a-booking-form-timepicker'
						:editable='false'
					>
						<template v-slot:icon-calendar>
							<v-icon icon="arrow-down" size="14" />
						</template>
					</date-picker>
				</div>
			</div>
			<div class="a-booking-form-col" v-if='!edit' :style='tourists.length ? "pointer-events: none; opacity: .5": ""'>
				<select-people
					:adults='form.adults'
					:childrenCount='form.childrenCount'
					:children='form.children'
					:placeholder='$t("account.booking.content.count_tourist")'
					@update-people='$event => form = {...form, ...$event}'
					compact
					:errors='!form.adults && errors.adults'
					:tourists='tourists'
				/>
			</div>
		</div>

		<template v-if='!edit'>
			<select-tourist
				@tourists='tourists = $event'
				@add-tourist='form.travelersIds = $event'
				@remove-tourist='form.travelersIds.splice($event, 1)'
			/>
		</template>

		<div class='row a-booking-form-action'>
			<div class="col">
				<template v-if='!edit'>
					<p class='a-booking-form-description' v-if='form.travelersIds.length >= 4'>{{$t("account.booking.content.max_number_tourists")}}</p>
				</template>
			</div>
			<div class="col-auto">
				<v-button small color='green white--text' @click='carsSearch' :loading='loading'>{{$t("account.booking.content.search")}}</v-button>
			</div>
		</div>
	</form>
</template>

<script>
	import SelectDefaults from "@/views/account/home/components/SelectDefaults";
	import SelectPeople from "@/views/account/home/components/SelectPeople";
	import SelectTourist from '@/views/account/home/components/SelectTourist';
	// import { carsSearch } from '@/services/request';

	export default {
		data: () => ({
			returnPlace: false,
			open: false,
			form: {
				dateFrom: null,
				dateTo: null,
				timeFrom: null,
				timeTo: null,
				airClass: null,
				adults: 1,
				childrenCount: 0,
				children: [],
				onlyDirectFlights: false,
				onlyWithBaggage: false,
				onlyRefundable: false,
				onlyTourPackages: false,
				travelersIds: [],
				airCompanies: null
			},
			tourists: [],
			loading: false,
			errors: {}
		}),
		props: ["edit"],
		components: {
			SelectDefaults,
			SelectPeople,
			SelectTourist
		},
		methods: {
			carsSearch() {
				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.loading = true;

				this.$router.push({
					name: "cars-search",
					params: {orderId: 1, storageId: 1, lang: this.$route.params.lang},
					query: {source: JSON.stringify(this.form)}
				})

				// let orderId = this.$route.params.orderId ? { orderId: this.$route.params.orderId } : false;

				// carsSearch({...this.form, ...orderId}).then(res => {
				// 	if(this.$route.params.storageId === res.data.storageId) {
				// 		this.$emit("update");
				// 	} else {
				// 		this.$router.push({
				// 			name: "flights-search",
				// 			params: {orderId: res.data.orderId, storageId: res.data.storageId, lang: this.$route.params.lang},
				// 			query: {source: JSON.stringify(this.form)}
				// 		})
				// 	}
				// }).catch(err => {
				// 	this.errors = err.response.data?.errors ?? {};
				// }).finally(() => this.loading = false)
			},
			changeTypes() {
				if(!this.form.dateFrom) return;

				const timestamp = this.$moment(this.form.dateFrom).valueOf();
				if(this.form.type === 'both') {
					this.form.date = [ timestamp, timestamp ];
					this.form.dateFrom = this.$moment(this.form.dateFrom).format('YYYY-MM-DD');
					this.form.dateTo = this.$moment(this.form.dateFrom).format('YYYY-MM-DD');
				} else {
					delete this.form.dateTo;
					this.form.dateFrom = this.$moment(this.form.dateFrom).format('YYYY-MM-DD');
					this.form.date = timestamp;
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-booking-form-cars {
		&-checkboxs {
			margin-top: 10px;
			margin-bottom: 16px;
		}
		&-tourists {
			padding-top: 32px;
		}
	}
</style>
